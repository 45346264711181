.kc-color-1{
  color:#0F2AD4;
}
.kc-color-1-bg{
  background-color:#0F2AD4;
}
.kc-color-2{
  color:#051687;
}
.kc-color-btn-n{
  background-color:#103d82;
}
.kc-color-btn-r{
  background-color:#103d82;
}
.kc-btn:hover,
.kc-btn:focus {
  text-decoration: none;
  cursor: pointer;
}
.kc-btn-color {
  background-color:#7ea2d1;
  transition: 0.2s;
}
.kc-btn-color:hover {
  background-color:#C3CCD9;
}
.image{
  text-align:center;
  width:100%;
  max-height:100%;
  height:auto;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
}
