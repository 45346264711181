@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  background-color: #edf2f7;
  color: #718096;
  font-family: HelveticaNeue, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
