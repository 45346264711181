body.active-modal {
    overflow-y: hidden;
}

.modal, .overlay {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: table;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 5px;
    border-radius: 3px;
    width: 50%;
    height: 45%;
    min-width: 320px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}

.close-modal {
    float:right;
    top: 0px;
    right: 10px;
    font-size: 30px;
    font-weight: bold;
}