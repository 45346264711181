.img-scale {
  width: 100%;
  height: auto;
}
.scrubber {
  position: absolute;
  font-size: 1.01vw;
  font-weight: bold;
  border-radius: 14px;
  padding: 2px;
  width: 2vw;
  display: none;
}
#timeline-graphic{
  width:100%;
}

.timeline-background{
    background-image: url('../Images/timeline.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    height:110px;
}
.timeline-date{
    position:relative;
    width:22px;
    color:white;
    padding:10px 5px 10px 5px;
    font-size: 1rem;font-weight: bold;
    line-height: 1rem;
    border-radius: 10px;
    border: 1px solid white;
}
.time-capsule {
    padding: 0.6em;
    border-radius: 1rem;
    display: inline-block;
    font-size: 50%;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out, border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out, font-size 0.5s ease-in-out;
}
.timeline-placeholder{
    padding: 0.6em;
    border-radius: 1rem;
    display: inline-block;
    font-size: 1rem;
    line-height: 1;
    opacity:0;
}
.tech-inactive {
    /* color: #000; */
    /*background-color: #E0E0E0;*/
}

@media screen and (min-width: 1024px) {
  .scrubber {
     font-size: 14px;
  }
}